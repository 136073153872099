:root {
  --themeMainColorDecRGB: 173, 0, 103;
  --themeAccentColorDecRGB: 255, 255, 255;
  --themeMainColor: rgb(var(--themeMainColorDecRGB));
  --themeAccentColor: rgb(var(--themeAccentColorDecRGB));
  background-color: var(--themeMainColor);
  background: linear-gradient(black, var(--themeMainColor));
}

.App {
  text-align: center;
  font-family:'orbitronregular','Roboto',Arial,sans-serif;
}

.App-logo {
  height: 20vh;
  pointer-events: none;
}

nav {
  margin: 0;
  padding: 0;
  min-height: 10vh;
}

h1 {
  font-size: 5vh;
  margin: 0;
}

h2 {
  font-size: 4vh;
}

.App-header {
  position: relative;
  min-height: 90vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 2.5vh;
  color: var(--themeAccentColor);
}

.App input[type=text] {
  display:inline-block;
  padding: 1vh 1vw;
  border: 0.1em solid var(--themeAccentColor);
  background-color: rgba(var(--themeAccentColorDecRGB), 0.1);
  margin: 0 0.5vw 0.5vw 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'orbitronregular','Roboto',Arial,sans-serif;
  font-weight:300;
  font-size: 2vh;
  color:var(--themeAccentColor);
  text-align:center;
  transition: all 0.2s;
  background-position: center;
  transition: background 0.8s;
  width: 60vw;
}

.App p {
  font-weight: 300;
}

.versionInfo {
  font-size: 2vh;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(var(--themeAccentColorDecRGB), 0.4);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(var(--themeAccentColorDecRGB), 0.4);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(var(--themeAccentColorDecRGB), 0.4);
}

.clickToReveal {
  padding: 1vh 1vw !important;
  margin: 0 0 0 0 !important;
}

.button, .clickToReveal {
  display:inline-block;
  padding: 1vh 4vw;
  border: 0.1em solid var(--themeAccentColor);
  margin: 0 0.5vw 0.5vw 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'orbitronregular','Roboto',Arial,sans-serif;
  font-weight:300;
  font-size: 2vh;
  text-align:center;
  transition: all 0.2s;
  background-position: center;
  transition: background 0.8s;
  color:var(--themeAccentColor);
  background: rgba(var(--themeAccentColorDecRGB), 0);
}

.button:hover, .clickToReveal:hover {
  color: var(--themeMainColor);
  cursor: pointer;
  background: var(--themeAccentColor) radial-gradient(circle, transparent 1%, var(--themeAccentColor) 1%) center/15000%;
}

.button:active, .clickToReveal:active {
  background-size: 100%;
  transition: background 0s;
}

.button-pulse {
  width: fit-content;
  margin: auto;
}

.button-pulse button {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.button__wrapper:hover .pulsing:before {
  animation: pulsing 0.2s linear infinite;
}
.button__wrapper:hover .pulsing:after {
  animation: pulsing1 0.2s linear infinite;
}
.pulsing {
  width: 100%;
	height: 100%;
	z-index: 1;
	position: relative;
}
.pulsing:before,
.pulsing:after {
  content: "";
	position: absolute;
  width: 100%;
  height: 100%;
  border: inherit;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(var(--themeAccentColorDecRGB), 0.5);
  z-index: 1;
  border-radius: inherit;
  animation: pulsing 2.5s linear infinite;
}
.pulsing:after {
  animation: pulsing1 2.5s linear infinite;
}
@keyframes pulsing {
	0% {
		opacity: 1;
		transform: scaleY(1) scaleX(1);
	}
	20% {
		opacity: 0.5;
	}
	70% {
		opacity: 0.2;
		transform: scaleY(1.8) scaleX(1.4);
	}
	80% {
		opacity: 0;
		transform: scaleY(1.8) scaleX(1.4);
	}
	90% {
		opacity: 0;
		transform: scaleY(1) scaleX(1);
	}
}
@keyframes pulsing1 {
	0% {
		opacity: 1;
		transform: scaleY(1) scaleX(1);
	}
	20% {
		opacity: 0.5;
	}
	70% {
		opacity: 0.2;
		transform: scaleY(1.3) scaleX(1.15);
	}
	80% {
		opacity: 0;
		transform: scaleY(1.3) scaleX(1.15);
	}
	90% {
		opacity: 0;
		transform: scaleY(1) scaleX(1);
	}
}

#signOut {
  font-size: 2vh !important;
  color: rgba(var(--themeAccentColorDecRGB), 0.5);
  border: 0.1em solid rgba(var(--themeAccentColorDecRGB), 0.5);
}
#signOut:hover {
  color: var(--themeMainColor);
}

#shufflebutton {
  height: 3vh;
  margin: 0 0 0 1vh;
  vertical-align: middle;
  cursor: pointer;
}

p .inlineIcon {
  height: 2.5vh;
}

h1 .inlineIcon {
  height: 5vh;
}

.inlineIcon,
.closeModalButton {
  height: 5vh;
  width: 5vh;
  margin: 0.5vh 0.5vw 0.5vw 0.5vh;
  vertical-align: middle;
  cursor: pointer;
}

.inlineIconSmall {
  height: 2vh;
  margin: 0 0.5vw 0.5vw 0.5vh;
  vertical-align: middle;
  cursor: pointer;
}

.navIcon {
  position: fixed;
  right: 1.5em;
  top: 1.5em;
  height: 3em;
  opacity: 0.5;
}

.actionableText {
  color: var(--themeAccentColor);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.verticallycentered {
  display: flex;
  align-items: center;
}

@media all and (max-width:30em){
  .button{
    display:block;
    margin:0.4em auto;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.fade-out {
  animation: fadeOut ease 1s;
  animation-fill-mode: forwards;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@font-face {
  font-family: 'orbitronregular';
  src: url('orbitron-variablefont_wght-webfont.woff2') format('woff2'),
       url('orbitron-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

#backgroundVideo {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#videoWrapper {
  /* Telling our absolute positioned video to 
  be relative to this element */
  position: relative;

  width: 100%;
  height: 100%;

  /* Will not allow the video to overflow the 
  container */
  overflow: hidden;

  /* Centering the container's content vertically 
  and horizontally */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pageWrap {
  margin: 0 10vw;
}

.header {
  position: relative;
  width: 100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 2.4em;
  height: 2em;
  left: 2em;
  top: 2em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: rgba(var(--themeAccentColorDecRGB), 0.5);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgba(var(--themeAccentColorDecRGB), 1);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  right: 1.5em !important;
  top: 1.5em !important;
  height: 3em !important;
  width: 3em !important;
}
.bm-cross-button span:not(.bm-cross) {
  top: 0 !important;
  right: 1.7em !important;
}

/* Color/shape of close button cross */
.bm-cross {
  height: 3em !important;
  width: 0.25em !important;
  background: var(--themeAccentColor);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(0, 0, 0, 0.5);
  border: 0.1em solid var(--themeMainColor);
  border-radius:0.12em;
  box-sizing: border-box;
  padding: 6em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgba(0, 0, 0, 0.5);
}

/* Wrapper for item list */
.bm-item-list {
  text-align: left;
  padding: 0 0.8em;
  height: calc(100vh - 10em) !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin: 1.5em 0;
  color: var(--themeAccentColor);
  text-decoration: none;
}

.bm-item:hover,
.bm-item:active
{
  color: var(--themeMainColor) !important;
  cursor: pointer;
}

.bm-item button {
  position: absolute;
  font-size: 1.15em !important;
  bottom: 0  !important;
  margin-bottom: 2em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.modal {
  position: absolute;
  top: 20vh;
  left: 15vw;
  right: 15vw;
  bottom: 20vh;
  padding: 5vh 10vw;
  background-color: rgba(0, 0, 0, 0.3);
  font-family:'orbitronregular','Roboto',Arial,sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--themeAccentColor);
  border:0.1em solid var(--themeAccentColor);
  border-radius:0.12em;
}

.modal::-webkit-scrollbar {
  width: 10px;
}

.modal::-webkit-scrollbar-track {
  background: rgba(var(--themeAccentColorDecRGB), 0.1);
}

.modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--themeMainColor);
  border: 0px solid var(--themeAccentColor);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal p,
.modal ol {
  font-family:'Roboto',Arial,sans-serif;
}

.modalFooter {
  text-align: center;
  height: 10vh;
  margin-top: 10vh;
}

.blur:not(.modal) {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}

p.keepWithNextLine {
  margin-bottom: 0;
}

p.keepWithPreviousLine {
  margin-top: 0;
}

.videoCardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border: solid;
  border-radius: 15px;
  margin: 2vmin auto;
  padding: 2vmin;
  font-size: 2vh;
  max-width: 90vmin;
}

.videoCardThumbnail img {
  width: 16vmin;
  height: 9vmin;
}

.videoCardLinkIcon {
  height: 4.5vmin;
  width: 4.5vmin;
  margin-left: 1vw;
}

.videoCardDetails {
  text-align: left;
  margin-left: 2vw;
  margin-right: auto;
}

p.videoCardSource {
  font-weight: bold;
}

p.videoCardTitle {
  max-width: 50vw;
  word-wrap: break-word;
}

.videoCardLink p {
  display: flex;
  margin: auto;
  vertical-align: middle;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
}